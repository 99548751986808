/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:ccad77cb-7b3d-45f7-8392-72ebc449b73e",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_LiV8NOgxZ",
    "aws_user_pools_web_client_id": "hdav8802s8q058otgpv1udatj",
    "oauth": {}
};


export default awsmobile;